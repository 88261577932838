import * as React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'


import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects = data.allMarkdownRemark.nodes
  

  if (projects.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No projects found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      

      <ul className="min-h-screen grid list-none p-10 w-full gap-0 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {projects.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const CoverPath = post.frontmatter.coverImage.childImageSharp.fluid

          return (
            <Link to={post.fields.slug} itemProp="url">
            <li key={post.fields.slug} className="post-thumb relative overflow-hidden h-30v min-h-10 transform hover:transition duration-500 hover:shadow-sm hover:scale-99">

              <section className="FeaturedImage absolute w-full h-30v transform hover:transition duration-150 hover:opacity-0">
                <BackgroundImage
                    Tag="section"
                    className='w-full h-full'
                    fluid={CoverPath}
                    backgroundColor={`#040e18`}
                  >
                  </BackgroundImage>
              </section>
              <header className="grid grid-cols-1 w-full h-full p-5 opacity-0 transform hover:transition duration-150 hover:opacity-100"> 
                <h2 className="place-self-center font-serif text-center text-5vh leading-none uppercase text-white">
                  {title}            
                </h2>
              </header>
                  
            </li>
            </Link>
          )
        })}
      </ul>
    </Layout>
  )
}

export default ProjectIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fileAbsolutePath: {regex: "/content/projects/"}}
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          coverImage {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG, formats: WEBP)
            }
            absolutePath
          }
        }
      }
    }
  }
`
